import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./listing.css";
import AboutData from "../../data/AdminAbout.json";

class Listing extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/" : `/${currentPageNum - 1}/`;
    const nextPage = `/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div className="paging-container">
        <div className="paging-section">
          {!isFirstPage && <Link to={prevPage}>Previous</Link>}
          {[...Array(pageCount)].map((_val, index) => {
            const pageNum = index + 1;
            return (
              <Link
                key={`listing-page-${pageNum}`}
                to={pageNum === 1 ? "/" : `/${pageNum}/`}
              >
                {pageNum}
              </Link>
            );
          })}
          {!isLastPage && <Link to={nextPage}>Next</Link>}
        </div>
      </div>
    );
  }
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <Helmet title={config.siteTitle} />
        <SEO />
        <div className="page-listing" >
          <section className="page-image-section">
            <div className="page-image-wrapper">
              <img
                className="page-image"
                alt={AboutData.title}
                src="https://res.cloudinary.com/dnnrexpjo/image/upload/v1601119927/7_lvlz9b.jpg"
              />
              <h1 className="page-title">Blog</h1>
              <div className="listing-container">
                <div className="post-container">
                  <PostListing postEdges={postEdges} />
                </div>
              </div>
            </div>
          </section>
        </div>
        {this.renderPaging()}
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date(locale: "TR", formatString: "LL")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date(locale: "TR", formatString: "LL")
          }
        }
      }
    }
  }
`;
